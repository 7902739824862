
export const fetchCurrentLocation = async (callback: (status: string, location?: any) => void) => {
  if (navigator?.geolocation) {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const google = await googleClient();
      if (!google) {
        callback('GOOGLE_SCRIPT_MISSING', null);
        return;
      }

      const service = new google.maps.Geocoder;
      service.geocode({ location: {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }}, (results, status) => {
        if (status != 'OK') {
          callback(status, null);
          return;
        }

        if (results.length > 0) {
          const formatted = formatPlaceToData(results[0]);
          callback(status, formatted);
        } else {
          callback('NO_RESULTS', null);
        }
      });
    });
  } else {
    callback('NAVIGATOR_MISSING', null);
  }
};

export const fetchLocation = async (placeId: string | string[], callback: (status: string, location?: any) => void) => {
  const request = {
    placeId: String(placeId),
    fields: ['geometry', 'formatted_address', 'address_component']
  };

  const google = await googleClient();
  if (!google) {
    return;
  }

  let map = new google.maps.Map(document.createElement('div'));
  const service = new google.maps.places.PlacesService(map);
  service.getDetails(request, (place, status) => {
    if (status != 'OK') {
      callback(null, status);
      return;
    }

    const formatted = formatPlaceToData(place);
    callback(status, formatted);
  });
}

// DATA

export const formatPlaceToData = (place) => {
  if (!place) return {};

  const data: any = {};

  const description = place.formatted_address;
  if (description) data.description = description;

  const coordinates = place.geometry?.location;
  if (coordinates) {
    data.lat = coordinates.lat().toString();
    data.lng = coordinates.lng().toString();
  }

  const placeId = place.place_id;
  if (placeId) data.placeId = placeId;

  const town = getAttribute('postal_town', place) || getAttribute('locality', place);
  if (town) data.city = town;

  const streetAddress = getAttribute('route', place);
  const premise = getAttribute('premise', place);
  const addressLine1 = streetAddress || premise;
  if (addressLine1) data.addressLine1 = addressLine1;

  const postcode = getAttribute('postal_code', place)
  if (postcode) data.postcode = postcode;

  const state = getAttribute('administrative_area_level_1', place);
  if (state) data.state = state;

  const country = getAttribute('country', place);
  if (country) data.country = country;

  return data;
};

export const formatForDeliveryEstimate = (input) => {
  if (!input) return {};
  var data: any = {};
  data = addIfOk('addressLine1', data, input);
  data = addIfOk('state', data, input);
  data = addIfOk('postcode', data, input);
  data = addIfOk('city', data, input);
  data = addIfOk('country', data, input);
  return data;
};

export const formatForAvailableShops = (input) => {
  if (!input) return "";
  var queryStr = "";
  const variables = [
    ['lat', 'latitude'],
    ['lng', 'longitude'],
    ['postcode', 'postcode'],
    ['city', 'city']
  ];
  for (const key of variables) {
    const [clientKey, serverKey] = key;
    const result = input[clientKey];
    if (result && result.length > 0) queryStr = `${queryStr}${queryStr.length > 0 ? '&' : ''}${serverKey}=${encodeURI(result)}`;
  }
  return { query: queryStr };
};

const addIfOk = (key, data, input) => {
  var updated = data;
  const result = input[key];
  if (result && result.length > 0) {
    updated[key] = result;
  }
  return updated;
}

const getAttribute = (attr, place) => {
  return place?.address_components?.find(x => x.types.includes(attr))?.long_name;
};

// HELPERS

export const googleClient = async () => {
  // @ts-ignore
  if (!global.window || !global.document) {
    return null
  }

  // @ts-ignore
  if (!global.window.google) {
    await new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB1UUCXh_fIHy0aZA7bPCXgANvpg42E17c&libraries=places';
      script.onload = resolve;
      script.async = false;
      document.head.appendChild(script);
    })
  }

  // @ts-ignore
  return global.window.google;
}

export default googleClient;
